module.exports = {
  'app.account.name': 'Quản lý người dùng',
  'app.account.createName': 'Thêm người dùng',
  'app.account.editName': 'Sửa thông tin người dùng',
  'app.account.fullName': 'Họ và tên',
  'app.account.gender': 'Giới tính',
  'app.account.gender.1': 'Nam',
  'app.account.gender.2': 'Nữ',
  'app.account.accountName': 'Tên đăng nhập',
  'app.account.password': 'Mật khẩu',
  'app.account.passwordNew': 'Nhập lại mật khẩu',
  'app.account.phone': 'Số điện thoại',
  'app.account.status': 'Trạng thái',
  'app.account.birthday': 'Ngày sinh',
  'app.account.avatar': 'Ảnh đại diện',
  'app.account.status.1': 'Kích hoạt',
  'app.account.status.2': 'Hủy',
  'app.account.active': 'Thao tác',
  'app.account.create': 'Thêm người dùng',
  'app.account.edit': 'Sửa thông tin',
  'app.account.permissions': 'Cấp quyền',
  'app.account.search': 'Tìm kiếm',
  'app.account.reset': 'Reset mật khẩu',
  'app.account.buttonOK': 'Lưu lại',
  'app.account.buttonCancel': 'Hủy',
  'app.account.resetTitle': 'Khởi tạo lại mật khẩu người dùng',
  'app.account.searchaccount': 'Tìm kiếm theo tên đăng nhập...',
};