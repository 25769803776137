module.exports = {
  'app.user.validate.passwordrequired': 'Nhập mật khẩu!',
  'app.user.validate.passwordmin': 'Vui lòng nhập từ 6 ký tự trở lên',
  'app.user.validate.password2required': 'Nhập xác nhận mật khẩu!',
  'app.user.validate.resetPass': 'Khởi tạo mật khẩu thành công',
  'app.user.validate.create': 'Thêm mới tài khoản thành công',
  'app.user.validate.edit': 'Cập nhật thông tin thành công',
  'app.user.validate.password2': 'Mật khẩu xác nhận bạn nhập không khớp!',
  'app.user.validate.userName': 'Bạn chưa nhập tên đăng nhập',
  'app.user.validate.userNamepattern': 'Tên đăng nhập phải là số điện thoại hoặc email',
  'app.user.validate.fullName': 'Nhập họ và tên!',
  'app.user.validate.fullNamepattern': 'Vui lòng nhập từ 3 đến 50 ký tự chỉ là chữ',
  'app.user.validate.gender': 'Nhập giới tính!',
  'app.user.validate.phone': 'Nhập số điện thoại!',
  'app.user.validate.phonepattern': 'Phải là số điện thoại !',
  'app.user.validate.birthday': 'Nhập ngày sinh',
  'app.user.validate.isUserNameApi': 'Tài khoản api từ 3 đến 50 ký tự, bao gồm chữ,số và dấu _',
}