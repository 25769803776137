module.exports = {
  'app.ad.crud.title': 'Sửa quảng cáo',

  'app.ad.crud.label.1': 'Tên quảng cáo',
  'app.ad.crud.label.2': 'Trạng thái',

  'app.ad.crud.placeholder.1': 'Tên quảng cáo',
  'app.ad.crud.placeholder.2': 'Chọn trạng thái',

  'app.ad.crud.status.0': 'Hủy',
  'app.ad.crud.status.1': 'Kích hoạt',
  'app.ad.crud.status.2': 'Chọn trạng thái',

};
