module.exports = {
  'app.galeryca.create.title': 'Thêm mới giao diện danh mục',

  'app.galeryca.create.label.1': 'Tên giao diện danh mục',
  'app.galeryca.create.label.2': 'Trạng thái',
  'app.galeryca.create.label.3': 'Thư mục',


  'app.galeryca.create.placeholder.1': 'Tên giao diện danh mục',
  'app.galeryca.create.placeholder.2': 'Chọn trạng thái',
  'app.galeryca.create.placeholder.3': 'Nhập tên thư mục',



  'app.galeryca.create.status.0': 'Hủy',
  'app.galeryca.create.status.1': 'Kích hoạt',
  'app.galeryca.create.status.2': 'Chọn trạng thái',
};
