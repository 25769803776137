module.exports = {
  'app.category.name': 'Quản lý chuyên mục',
  'app.category.createName': 'Thêm mới chuyên mục',
  'app.category.editName': 'Sửa thông tin chuyên mục',
  'app.category.table.name': 'Tên chuyên mục',
  'app.category.table.website': 'Chọn tên website',
  'app.category.table.usersCreatorId': 'Người tạo',
  'app.category.table.createDate': 'Ngày tạo',
  'app.category.table.status': 'Trạng thái',
  'app.category.status.1': 'Kích hoạt',
  'app.category.status.2': 'Hủy',
  'app.category.active': 'Thao tác',
  'app.category.create': 'Thêm mới chuyên mục',
  'app.category.edit': 'Sửa thông tin',
  'app.category.search': 'Tìm kiếm',
  'app.category.gateNumber': 'Tìm kiếm theo tên chuyên mục',
  'app.category.buttonOK': 'Lưu lại',
  'app.category.buttonCancel': 'Hủy',
  'app.category.portName': 'Tìm kiếm theo cổng kết nối',
  'app.category.groupcategoryName': 'Tìm kiếm theo nhóm chuyên mục',
  'app.category.search.placeholder.date1': 'Ngày bắt đầu',
  'app.category.search.placeholder.date2': 'Ngày kết thúc',
};
