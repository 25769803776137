module.exports = {
  'app.adsType.create.title': 'Thêm mới loại quảng cáo',

  'app.adsType.create.label.1': 'Tên loại quảng cáo',
  'app.adsType.create.label.2': 'Trạng thái',
  'app.adsType.create.label.3': 'Trang web',

  
  'app.adsType.create.placeholder.1': 'Tên loại quảng cáo',
  'app.adsType.create.placeholder.2': 'Trạng thái',
  'app.adsType.create.placeholder.3': 'Trang web',



  'app.adsType.create.status.0': 'Hủy',
  'app.adsType.create.status.1': 'Kích hoạt',
  'app.adsType.create.status.2': 'Chọn trạng thái',
};
