module.exports = {
  'app.galeryca.crud.title': 'Sửa giao diện danh mục',

  'app.galeryca.crud.label.1': 'Tên giao diện danh mục',
  'app.galeryca.crud.label.2': 'Trạng thái',


  'app.galeryca.crud.status.0': 'Hủy',
  'app.galeryca.crud.status.1': 'Kích hoạt',
  'app.galeryca.crud.status.2': 'Chọn trạng thái',

};
