module.exports = {
  // Xem--------------------------------------------------------------
  // Title----------------------------------------------
  // Tiêu đề
  'app.groupPlace.list.title.header': 'Quản lý nhóm cơ sở y tế',
  'app.groupPlace.update.title.header': 'Sửa thông tin nhóm cơ sở y tế',
  'app.groupPlace.create.title.header': 'Thêm mới nhóm cơ sở y tế',


  // Tiêu đề các cột    
  'app.groupPlace.list.title.col1': 'Tên nhóm cơ sở y tế',
  'app.groupPlace.list.title.col3': 'Ngày tạo',
  'app.groupPlace.list.title.col2': 'Người tạo',
  'app.groupPlace.list.title.col4': 'Trạng thái',
  

                           
  

};