module.exports = {
  'app.user.name': 'Quản lý tài khoản',
  'app.user.createName': 'Thêm mới tài khoản',
  'app.user.editName': 'Sửa thông tin tài khoản',
  'app.user.fullName': 'Họ và tên',
  'app.user.gender': 'Giới tính',
  'app.user.gender.1': 'Nam',
  'app.user.gender.2': 'Nữ',
  'app.user.userName': 'Tên đăng nhập',
  'app.user.password': 'Mật khẩu',
  'app.user.passwordNew': 'Nhập lại mật khẩu',
  'app.user.phone': 'Số điện thoại',
  'app.user.status': 'Trạng thái',
  'app.user.birthday': 'Ngày sinh',
  'app.user.avatar': 'Ảnh đại diện',
  'app.user.status.1': 'Kích hoạt',
  'app.user.status.2': 'Hủy',
  'app.user.active': 'Thao tác',
  'app.user.create': 'Thêm mới tài khoản',
  'app.user.edit': 'Sửa thông tin',
  'app.user.permissions': 'Cấp quyền',
  'app.user.search': 'Tìm kiếm',
  'app.user.reset': 'Reset mật khẩu',
  'app.user.buttonOK': 'Lưu lại',
  'app.user.buttonCancel': 'Hủy',
  'app.user.resetTitle': 'Khởi tạo lại mật khẩu tài khoản',
  'app.user.searchUser': 'Tìm kiếm theo tên đăng nhập...',
  'app.user.userNameApi': 'Tài khoản API',
  'app.user.passwordApi': 'Mật khẩu API',
  'app.user.listIp': 'Danh sách Ip',
  'app.user.addIp': 'Thêm Ip',
  "app.user.table.index":"STT"
};
