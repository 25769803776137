module.exports = {
  'app.galery.crud.title': 'Sửa thư viện giao diện',

  'app.galery.crud.label.1': 'Tên thư viện giao diện',
  'app.galery.crud.label.2': 'Trạng thái',


  'app.galery.crud.status.0': 'Hủy',
  'app.galery.crud.status.1': 'Kích hoạt',
  'app.galery.crud.status.2': 'Chọn trạng thái',

};
