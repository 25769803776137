module.exports = {
  'app.groupWebsites.name': 'Quản lý nhóm website',
  'app.groupWebsites.createName': 'Thêm mới nhóm website',
  'app.groupWebsites.editName': 'Sửa thông tin nhóm website',
  'app.groupWebsites.table.name': 'Tên nhóm website',
  'app.groupWebsites.table.website': 'Tên website',
  'app.groupWebsites.table.usersCreatorId': 'Người tạo',
  'app.groupWebsites.table.createDate': 'Ngày tạo',
  'app.groupWebsites.table.status': 'Trạng thái',
  'app.groupWebsites.status.1': 'Kích hoạt',
  'app.groupWebsites.status.2': 'Hủy',
  'app.groupWebsites.active': 'Thao tác',
  'app.groupWebsites.create': 'Thêm mới nhóm website',
  'app.groupWebsites.edit': 'Sửa thông tin',
  'app.groupWebsites.search': 'Tìm kiếm',
  'app.groupWebsites.gateNumber': 'Tìm kiếm theo nhóm website',
  'app.groupWebsites.buttonOK': 'Lưu lại',
  'app.groupWebsites.buttonCancel': 'Hủy',
  'app.groupWebsites.portName': 'Tìm kiếm theo cổng kết nối',
  'app.groupWebsites.groupcategoryName': 'Tìm kiếm theo nhóm website',
  'app.groupWebsites.search.placeholder.date1': 'Ngày bắt đầu',
  'app.groupWebsites.search.placeholder.date2': 'Ngày kết thúc',
};
