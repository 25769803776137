module.exports = {
  'app.menu.validate.passwordrequired': 'Nhập mật khẩu!',
  'app.menu.validate.order': 'Số thứ tự phải là số nguyên dương!',
  'app.menu.validate.name': 'trường định danh phải là duy nhất',
  'app.menu.validate.create': 'Thêm menu thành công',
  'app.menu.validate.edit': 'Cập nhật thông tin thành công',
  'app.menu.validate.Title': 'Bạn chưa nhập Tên menu!',
  'app.menu.validate.Namerequired': 'Bạn chưa nhập định danh!',
  'app.menu.validate.Namepattern': 'Định danh chỉ được nhập chữ, số và /!@#$%^&*!',
  'app.menu.validate.MenuParentId': 'Bạn chưa chọn menu cha!',
  'app.menu.validate.Url': 'Vui lòng nhập đúng định dạng đường dẫn!',
  'app.menu.validate.Component': 'Thành phần chỉ được nhập chữ, số và /',
  'app.menu.validate.Image': 'Bạn chưa nhập Icon',
  'app.menu.validate.Orderrequired': 'Bạn chưa nhập thứ tự!',
  'app.menu.validate.Status': 'Bạn chưa chọn trạng thái!',
}