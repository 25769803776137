module.exports = {
  'app.adsPosition.create.title': 'Thêm mới vị trí quảng cáo',

  'app.adsPosition.create.label.1': 'Tên quảng cáo',
  'app.adsPosition.create.label.2': 'Trạng thái',
  'app.adsPosition.create.label.3': 'Trang web',

  
  'app.adsPosition.create.placeholder.1': 'Tên quảng cáo',
  'app.adsPosition.create.placeholder.2': 'Trạng thái',
  'app.adsPosition.create.placeholder.3': 'Trang web',


  'app.adsPosition.create.status.0': 'Hủy',
  'app.adsPosition.create.status.1': 'Kích hoạt',
  'app.adsPosition.create.status.2': 'Chọn trạng thái',
};
